export const faqData = [
  {
    id: 1,
    question: "누구세요?",
    answer: "안녕하세요. 저는 프론트엔드 개발자 강성복입니다.",
    created_dt: "2024-09-03 13:56:22",
    updated_dt: "2024-09-03 13:56:22",
  },
  {
    id: 2,
    question: "어디에 거주하시나요?",
    answer: "저는 대한민국 경기도 의왕에 살고 있습니다.",
    created_dt: "2024-09-03 13:56:22",
    updated_dt: "2024-09-03 13:56:22",
  },
  {
    id: 3,
    question: "이 사이트는 무엇을 위한 것입니까?",
    answer: "이 사이트는 제 소개와 제가 만든 프로젝트를 소개하기 위한 사이트입니다.",
    created_dt: "2024-09-03 13:56:22",
    updated_dt: "2024-09-03 13:56:22",
  },
  {
    id: 4,
    question: "어떠한 업무를 할 수 있습니까?",
    answer: "저는 디자인부터 프론트엔드, 백엔드까지 다양한 업무를 수행할 수 있습니다.",
    created_dt: "2024-09-03 13:56:22",
    updated_dt: "2024-09-03 13:56:22",
  },
  {
    id: 5,
    question: "어떻게 연락할 수 있습니까?",
    answer: "Home 또는 About 메뉴에 있는 전화번호와 이메일로 연락하실 수 있습니다.",
    created_dt: "2024-09-03 13:56:22",
    updated_dt: "2024-09-03 13:56:22",
  },
];